import log from 'loglevel';
import axios from 'axios';

log.setLevel('debug');

const sendLogToBackend = async (level, message, metadata = {}) => {
  try {

    const logData = {
      level: level,
      message: message,
      user_id: sessionStorage.getItem('user_id') || 'anonymous',
      role: sessionStorage.getItem('role') || 'unknown',
      project: sessionStorage.getItem('project') || 'unknown',
      session_id: sessionStorage.getItem('session_id') || 'N/A',
      endpoint: metadata.endpoint || window.location.pathname,
      method: metadata.method || 'N/A',
      response_status: metadata.response_status || 'N/A',
      // client_type: 'frontend',  // New field to differentiate frontend logs
      timestamp: new Date().toISOString(),
    };
    const response = await axios.post('/operator/log/', logData);
    // const response = await axios.post('/operator/log/', {
    //   level: level,
    //   message: message,
    //   user_id: sessionStorage.getItem('user_id') || 'anonymous',
    //   role: sessionStorage.getItem('role') || 'unknown',
    //   project: sessionStorage.getItem('project') || 'unknown',
    //   session_id: sessionStorage.getItem('session_id') || 'N/A',
    //   endpoint: metadata.endpoint || window.location.pathname,
    //   method: metadata.method || 'N/A',
    //   response_status: metadata.responseStatus || 'N/A',
    //   timestamp: new Date().toISOString(),
    // });
    if (response.status !== 200) {
      console.error('Failed to send log to backend:', response);
    }
  } catch (error) {
    console.error('Error sending log to backend:', error);
  }
};

// Override log methods to send logs to backend
// const originalFactory = log.methodFactory;
// log.methodFactory = (methodName, logLevel, loggerName) => {
//   const rawMethod = originalFactory(methodName, logLevel, loggerName);
//   return (...args) => {
//     rawMethod(...args);
//     sendLogToBackend(methodName, args.join(' '));
//   };
// };

const originalFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return (message, metadata = {}) => {
    // Call the original log method
    rawMethod(message);

    // Send the log to the backend
    sendLogToBackend(methodName, message, metadata);
  };
};

log.setLevel(log.getLevel());

export default log;
