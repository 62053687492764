import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import "../Operator/OperatorProfile.css";
import NavbarNew from "../Navbar/NavbarNew";
import questionMark from "../Images/Vector.png";
import Footer from "../Operator/Footer";
import axios from "axios";
import Sidebar from "../Operator/components/SidebarNew";
import edit from "../Operator/SVGs/edit.svg";
import useIdleTimer from "../../useIdleTimer";

const AdminProfile = () => {
    const handleIdle = () => {
        console.log("User is idle, logging out...");
        window.location.href = "/";
    }
    useIdleTimer(handleIdle, 1.2e6);

    const project = sessionStorage.getItem("project");
    const role = sessionStorage.getItem("role");

    const storedUserObjectString = sessionStorage.getItem("userObject");
    const user = JSON.parse(storedUserObjectString);
    const username = user.first_name;

    const operator = user


    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [editedOperator, setEditedOperator] = useState({ ...operator });
    const [errors, setErrors] = useState({
        mobile_number: "",
        last_name: "",
        first_name: "",
        address_1: "",
        city: "",
        state: "",
        zip_code: "",
        email: "",
    });
    const operatorRole = role

    const hasErrors = Object.values(errors).some((error) => error !== "");

    const [isDropdownOpen, setIsDropdownOpen] = useState({
        gender: false,
        race: false,
        state: false,
        role: false
    });

    const [selectedValues, setSelectedValues] = useState({
        gender: editedOperator.gender || "",
        race: editedOperator.race || "",
        role: operatorRole
    });

    const states = [
        { code: "AL", name: "Alabama" },
        { code: "AK", name: "Alaska" },
        { code: "AZ", name: "Arizona" },
        { code: "AR", name: "Arkansas" },
        { code: "CA", name: "California" },
        { code: "CO", name: "Colorado" },
        { code: "CT", name: "Connecticut" },
        { code: "DE", name: "Delaware" },
        { code: "FL", name: "Florida" },
        { code: "GA", name: "Georgia" },
        { code: "HI", name: "Hawaii" },
        { code: "ID", name: "Idaho" },
        { code: "IL", name: "Illinois" },
        { code: "IN", name: "Indiana" },
        { code: "IA", name: "Iowa" },
        { code: "KS", name: "Kansas" },
        { code: "KY", name: "Kentucky" },
        { code: "LA", name: "Louisiana" },
        { code: "ME", name: "Maine" },
        { code: "MD", name: "Maryland" },
        { code: "MA", name: "Massachusetts" },
        { code: "MI", name: "Michigan" },
        { code: "MN", name: "Minnesota" },
        { code: "MS", name: "Mississippi" },
        { code: "MO", name: "Missouri" },
        { code: "MT", name: "Montana" },
        { code: "NE", name: "Nebraska" },
        { code: "NV", name: "Nevada" },
        { code: "NH", name: "New Hampshire" },
        { code: "NJ", name: "New Jersey" },
        { code: "NM", name: "New Mexico" },
        { code: "NY", name: "New York" },
        { code: "NC", name: "North Carolina" },
        { code: "ND", name: "North Dakota" },
        { code: "OH", name: "Ohio" },
        { code: "OK", name: "Oklahoma" },
        { code: "OR", name: "Oregon" },
        { code: "PA", name: "Pennsylvania" },
        { code: "RI", name: "Rhode Island" },
        { code: "SC", name: "South Carolina" },
        { code: "SD", name: "South Dakota" },
        { code: "TN", name: "Tennessee" },
        { code: "TX", name: "Texas" },
        { code: "UT", name: "Utah" },
        { code: "VT", name: "Vermont" },
        { code: "VA", name: "Virginia" },
        { code: "WA", name: "Washington" },
        { code: "WV", name: "West Virginia" },
        { code: "WI", name: "Wisconsin" },
        { code: "WY", name: "Wyoming" },
    ];

    const [selectedState, setSelectedState] = useState(editedOperator.state || "Select State");
    const [isStateDropdownOpen, setIsStateDropdownOpen] = useState(false);

    const handleStateSelect = (stateCode) => {
        setSelectedState(stateCode);
        setEditedOperator((prevOperator) => ({
            ...prevOperator,
            state: stateCode,
        }));
        setIsStateDropdownOpen(false);
    };

    const handleDropdownSelect = (key, value) => {
        setSelectedValues((prevValues) => ({
            ...prevValues,
            [key]: value,
        }));

        setEditedOperator((prevOperator) => ({
            ...prevOperator,
            [key]: value,
        }));

        setIsDropdownOpen((prevOpen) => ({
            ...prevOpen,
            [key]: false,
        }));
    };

    const toggleDropdown = (key) => {
        setIsDropdownOpen((prevOpen) => ({
            ...prevOpen,
            [key]: !prevOpen[key],
        }));
    };

    const getToken = () => {
        const token = sessionStorage.getItem("admin_token");
        return token;
    };

    const isValidMobileNumber = (number) => {
        return /^\d{10}$/.test(number);
    };

    const isValidZipCode = (number) => {
        return /^\d{5}$/.test(number);
    };

    const isValidEmail = (email) => {
        return /^(?!\.)[\w\.-]+(\.[\w\.-]+)*@[a-zA-Z\d-]+(\.[a-zA-Z\d-]+)*(\.[a-zA-Z]{2,})$/.test(
            email
        );
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        let error = "";

        switch (name) {
            case "mobile_number":
                if (!isValidMobileNumber(value)) {
                    error = "Invalid mobile number";
                }
                break;
            case "last_name":
                if (value.trim() === "") {
                    error = "Last name is required";
                }
                break;
            case "first_name":
                if (value.trim() === "") {
                    error = "First name is required";
                }
                break;
            case "address_1":
                if (value.trim() === "") {
                    error = "Address is required";
                }
                break;
            case "city":
                if (value.trim() === "") {
                    error = "City is required";
                }
                break;
            case "state":
                if (value === "State") {
                    error = "State is required";
                }
                break;
            case "email":
                if (!isValidEmail(value)) {
                    error = "Invalid Email";
                }
                break;
            case "zip_code":
                if (value.trim() === "") {
                    error = "Zip code is required";
                } else if (!isValidZipCode(value)) {
                    error = "Invalid Zip code";
                }
                break;
            default:
                break;
        }
        setEditedOperator({ ...editedOperator, [name]: value });
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: error,
        }));
    };

    const handleSave = async () => {
        if (hasErrors) {
            setEditedOperator((editedOperator) => ({
                ...editedOperator,
                first_name: errors.first_name ? "" : editedOperator.first_name,
                last_name: errors.last_name ? "" : editedOperator.last_name,
                mobile_number: errors.mobile_number ? "" : editedOperator.mobile_number,
            }));
            return;
        }
        try {
            const token = getToken();
            const response = await axios.post(
                `/admin/update_admin_profile/`,
                editedOperator,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        project,
                    },
                }
            );

            if (response.status === 200) {

                setIsEditing(false);

                const updatedResponse = await axios.post(`/admin/get_admin_profile/`,
                    {
                        email: operator.email,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            project,
                        },
                    }
                );

                console.log("Response", updatedResponse.data)

                if (updatedResponse.status === 200) {
                    const updatedOperator = updatedResponse.data.admin;

                    setEditedOperator(updatedOperator);
                    sessionStorage.setItem("userObject", JSON.stringify(updatedOperator));
                }
            }
            else {

            }

        }


        catch (error) {
            console.error("Error updating profile:", error);
        }
    };

    const handleCancel = () => {
        setIsEditing(false);
        setEditedOperator({ ...operator });
    };

    useEffect(() => {
        const checkAuth = async () => {
            const token = getToken();

            if (token) {
                try {
                    const response = await axios.get("/operator/verify_token/", {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            project,
                        },
                    });

                    if (response.status === 200) {
                        // Token verified successfully
                    }
                } catch (error) {
                    if (error.request.status === 401) {
                        const refreshResponse = await axios.post(
                            "/operator/refresh/",
                            {},
                            { withCredentials: true }
                        );

                        if (refreshResponse.status === 200) {
                            sessionStorage.setItem(
                                "operator_token",
                                refreshResponse.data.access_token
                            );
                        } else {
                            handleLogout();
                        }
                    }
                }
            }
            setIsLoading(false);

        };

        checkAuth();
    }, [project]);

    const handleLogout = async () => {
        await axios.post("/operator/logout/", {}, { withCredentials: true });
        sessionStorage.clear();
    };

    return isLoading ? (
        <div></div>
    ) : (
        <div className="page-body">
            <NavbarNew username={username} />
            <div className="page-layout">
                <Sidebar />
                <div className="main-content">
                    <div className="patient-list-container">
                        <div className="first-line">
                            <div className="title-container">
                                <h2 className="inner-page-title" data-testid="admin-profile">Admin Profile</h2>
                                <div className="tooltip-container">
                                    <img
                                        src={questionMark}
                                        alt="info-icon"
                                        className="info-icon"
                                    />
                                    <span className="tooltip-text">
                                        View provider profile here
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="patient-profile-container">
                        <div className="middle-line">
                            <div className="profile-title-container">
                                <h2 className="inner-page-title">
                                    {operator.first_name}
                                </h2>
                                <h2 className="header-value-2">
                                    <span className="role-label">Role:</span>
                                    Admin
                                </h2>

                            </div>

                            {isEditing ? (
                                <div className="form-buttons-edit-profile">
                                    <button
                                        type="button"
                                        className="edit-profile-cancel"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className="edit-profile"
                                        onClick={handleSave}
                                        disabled={hasErrors}
                                    >
                                        Save
                                    </button>
                                </div>
                            ) : (
                                <div className="form-buttons-edit">
                                    <button
                                        onClick={() => setIsEditing(true)}
                                        className="edit-profile"
                                    >
                                        <p>Edit</p>{" "}
                                        <img src={edit} alt="logo" className="edit-svg" />
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="profile-rectangles">
                            <div className="details-container">
                                <div className="details-row">
                                    <span className="details-key">Mobile Number</span>
                                    <span className="detail-colon">:</span>
                                    <span className="details-value">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                name="mobile_number"
                                                value={editedOperator.mobile_number}
                                                onChange={handleInputChange}
                                                placeholder="Mobile Number"
                                                className={`details-value-5 input-edit ${errors.mobile_number ? "input-error" : ""
                                                    }`}
                                                required
                                            />
                                        ) : (
                                            <div className="details-value-5">
                                                {editedOperator.mobile_number || ""}
                                            </div>
                                        )}
                                        {errors.mobile_number && (
                                            <div className="error-message-edit-patient">
                                                {errors.mobile_number}
                                            </div>
                                        )}
                                    </span>
                                </div>
                                <div className="details-row">
                                    <span className="details-key">Email</span>
                                    <span className="detail-colon">:</span>
                                    <span className="details-value">
                                        {isEditing ? (
                                            <input
                                                type="text"
                                                name="email"
                                                value={editedOperator.email}
                                                placeholder="Email"
                                                onChange={handleInputChange}
                                                className={`details-value-5 input-edit ${errors.email ? "input-error" : ""
                                                    }`}
                                                required
                                            />
                                        ) : (
                                            <div className="profile-value-5">
                                                {editedOperator.email || ""}
                                            </div>
                                        )}
                                        {errors.email && (
                                            <div className="error-message-edit-patient">
                                                {errors.email}
                                            </div>
                                        )}
                                    </span>
                                </div>
                                <div className="details-row">
                                    <span className="details-key">Age</span>
                                    <span className="detail-colon">:</span>
                                    <span className="details-value">
                                        {isEditing ? (
                                            <input
                                                type="number"
                                                name="age"
                                                placeholder="Age"
                                                value={editedOperator.age}
                                                onChange={handleInputChange}
                                                className="details-value-5 input-edit"
                                                required
                                            />
                                        ) : (
                                            <div className="profile-value-5">
                                                {editedOperator.age || ""}
                                            </div>
                                        )}
                                    </span>
                                </div>
                                <div className="details-row">
                                    <span className="details-key">Gender</span>
                                    <span className="detail-colon">:</span>
                                    <span className="details-value">
                                        {isEditing ? (
                                            <div className="custom-dropdown-container-operator-profile">
                                                <div
                                                    className="custom-dropdown-header-operator-profile"
                                                    role="button"
                                                    aria-label={`Gender: ${selectedValues.gender || "Select Gender"}`}
                                                    onClick={() => toggleDropdown("gender")}
                                                >
                                                    {editedOperator.gender || "Select Gender"}
                                                    <span className={`custom-dropdown-arrow-operator-profile ${isDropdownOpen.gender ? "open" : ""}`}>
                                                        ▼
                                                    </span>
                                                </div>
                                                {isDropdownOpen.gender && (
                                                    <ul className="custom-dropdown-list-operator-profile">
                                                        <li onClick={() => handleDropdownSelect("gender", "Male")} className="custom-dropdown-item">
                                                            Male
                                                        </li>
                                                        <li onClick={() => handleDropdownSelect("gender", "Female")} className="custom-dropdown-item">
                                                            Female
                                                        </li>
                                                        <li onClick={() => handleDropdownSelect("gender", "Other")} className="custom-dropdown-item">
                                                            Other
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="profile-value-5">{editedOperator.gender || ""}</div>
                                        )}
                                    </span>
                                </div>
                                <div className="details-row">
                                    <span className="details-key">Race</span>
                                    <span className="detail-colon">:</span>
                                    <span className="details-value">
                                        {isEditing ? (
                                            <div className="custom-dropdown-container-operator-profile">
                                                <div
                                                    className="custom-dropdown-header-operator-profile"
                                                    onClick={() => toggleDropdown("race")}
                                                >
                                                    {editedOperator.race || "Select Race"}
                                                    <span className={`custom-dropdown-arrow-operator-profile ${isDropdownOpen.race ? "open" : ""}`}>
                                                        ▼
                                                    </span>
                                                </div>
                                                {isDropdownOpen.race && (
                                                    <ul className="custom-dropdown-list-operator-profile">
                                                        <li onClick={() => handleDropdownSelect("race", "Asian")} className="custom-dropdown-item">
                                                            Asian
                                                        </li>
                                                        <li onClick={() => handleDropdownSelect("race", "Black or African American")} className="custom-dropdown-item">
                                                            Black or African American
                                                        </li>
                                                        <li onClick={() => handleDropdownSelect("race", "Hispanic or Latino")} className="custom-dropdown-item">
                                                            Hispanic or Latino
                                                        </li>
                                                        <li onClick={() => handleDropdownSelect("race", "Native American or American Indian")} className="custom-dropdown-item">
                                                            Native American or American Indian
                                                        </li>
                                                        <li onClick={() => handleDropdownSelect("race", "White")} className="custom-dropdown-item">
                                                            White
                                                        </li>
                                                        <li onClick={() => handleDropdownSelect("race", "Other")} className="custom-dropdown-item">
                                                            Other
                                                        </li>
                                                    </ul>
                                                )}
                                            </div>
                                        ) : (
                                            <div className="profile-value-5">{editedOperator.race || ""}</div>
                                        )}
                                    </span>
                                </div>
                                <div className="details-row">
                                    <span className="details-key">Address</span>
                                    <span className="detail-colon">:</span>
                                    <span className="details-value">
                                        {isEditing ? (
                                            <>
                                                <div className="address-lines">
                                                    <input
                                                        type="text"
                                                        name="address_1"
                                                        placeholder="Address line-1"
                                                        value={editedOperator.address_1}
                                                        onChange={handleInputChange}
                                                        className="details-value-5 input-edit"
                                                    />

                                                    <input
                                                        type="text"
                                                        name="address_2"
                                                        placeholder="address_2"
                                                        value={editedOperator.address_2}
                                                        onChange={handleInputChange}
                                                        className="details-value-5 input-edit"
                                                    />
                                                    <div className="address-line-last">
                                                        <input
                                                            type="text"
                                                            name="city"
                                                            placeholder="City"
                                                            value={editedOperator.city}
                                                            onChange={handleInputChange}
                                                            className="details-value-5 input-edit"
                                                        />

                                                        <div className="custom-dropdown-container-operator-profile">
                                                            <div
                                                                className="custom-dropdown-header-operator-profile"
                                                                onClick={() => setIsStateDropdownOpen(!isStateDropdownOpen)}
                                                            >
                                                                {selectedState || "State"}
                                                                <span className={`custom-dropdown-arrow-operator-profile ${isStateDropdownOpen ? "open" : ""}`}>
                                                                    ▼
                                                                </span>
                                                            </div>
                                                            {isStateDropdownOpen && (
                                                                <ul className="custom-dropdown-list-operator-profile">
                                                                    {states.map((state) => (
                                                                        <li
                                                                            key={state.code}
                                                                            onClick={() => handleStateSelect(state.code)}
                                                                            className={`custom-dropdown-item ${selectedState === state.code ? "selected" : ""}`}
                                                                        >
                                                                            {state.name}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>

                                                        <input
                                                            type="text"
                                                            name="zip_code"
                                                            placeholder="Zip"
                                                            value={editedOperator.zip_code}
                                                            onChange={handleInputChange}
                                                            className="details-value-5 input-edit"
                                                        />
                                                    </div>
                                                </div>
                                                {errors.address_1 && <div className="error-message">{errors.address_1}</div>}
                                                {errors.city && <div className="error-message">{errors.city}</div>}
                                                {errors.state && <div className="error-message">{errors.state}</div>}
                                                {errors.zip_code && <div className="error-message">{errors.zip_code}</div>}
                                            </>
                                        ) : (
                                            <div className="address-lines">
                                                <div className="profile-value-5">
                                                    {editedOperator.address_1 || ""}
                                                </div>
                                                <div className="profile-value-5">
                                                    {editedOperator.address_2 || ""}
                                                </div>
                                                <div className="address-line-last">
                                                    <div className="profile-value-5">
                                                        {editedOperator.city || ""}
                                                    </div>

                                                    <div className="profile-value-5">
                                                        {editedOperator.state || ""}
                                                    </div>

                                                    <div className="profile-value-5">
                                                        {editedOperator.zip_code || ""}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default AdminProfile;
