import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import '../Operator/PatientRow.css';
import './OperatorRow.css';
import delIcon from "../Images/weui_delete-filled.png";
import cross from "../Images/fluent_warning-32-regular.png";
import physician_thumbsup_image from "../Images/simple-line-icons_check.png";

const OperatorRow = ({ operator, handleGetOperators }) => {
    const project = sessionStorage.getItem("project");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const navigate = useNavigate();

    const getToken = () => {
        const token = sessionStorage.getItem("admin_token");
        return token;
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
    };

    const handleRoleClick = async () => {
        const token = getToken();

        try {
            const response = await axios.post(`/admin/get_operator_profile/`,
                { email: operator.email },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        project: project,
                    },
                }
            );

            if (response.status === 200) {
                console.log("Operator profile fetched successfully:", response.data);
                navigate("/operatorProfile", {
                    state: { operator: response.data.operator }
                });
            } else {
                console.error('Failed to fetch operator profile');
            }
        } catch (error) {
            console.log(error)
        }
    };

    const confirmDelete = async (email, e) => {
        e.stopPropagation();

        try {
            const token = getToken();
            sessionStorage.setItem("selectedOperatorEmail", email);
            const response = await axios.delete(`/admin/delete_operator/${email}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    project: project,
                },
            });

            if (response.status === 200) {
                console.log("Operator deleted successfully!");
                setIsModalOpen(false);
                setIsSuccessModalOpen(true);
                handleGetOperators(); // Refresh the operator list
            } else {
                setIsModalOpen(false);
                setIsErrorModalOpen(true);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                try {
                    const refreshResponse = await axios.post("/admin/refresh/", {}, { withCredentials: true });
                    const storedEmail = sessionStorage.getItem("selectedOperatorEmail");

                    if (refreshResponse.status === 200) {
                        sessionStorage.setItem("admin_token", refreshResponse.data.access_token);
                        if (storedEmail) {
                            await confirmDelete(storedEmail);
                        } else {
                            console.error("No email found for the selected operator.");
                            setIsErrorModalOpen(true);
                        }
                    } else {
                        handleLogout();
                    }
                } catch (refreshError) {
                    console.error("Error refreshing token:", refreshError);
                    setIsModalOpen(false);
                    setIsErrorModalOpen(true);
                }
            } else {
                console.error("Error deleting operator:", error);
                setIsModalOpen(false);
                setIsErrorModalOpen(true);
            }
        }
    };

    const handleLogout = async () => {
        await axios.post("/admin/logout/", {}, { withCredentials: true });
        sessionStorage.clear();
    };

    const cancelDelete = (e) => {
        e.stopPropagation();
        setIsModalOpen(false);
    };

    const closeSuccessModal = (e) => {
        e.stopPropagation();
        setIsSuccessModalOpen(false);
    };

    const closeErrorModal = (e) => {
        e.stopPropagation();
        setIsErrorModalOpen(false);
    };

    return (
        <div className="operator-row" onClick={handleRoleClick} style={{ cursor: 'pointer' }}>
            <div className="operator-cell">{operator.provider_id}</div>
            <div className="operator-cell">{operator.first_name}</div>
            <div className="operator-cell">{operator.last_name}</div>
            <div className="operator-cell">{operator.email}</div>
            <div className="operator-cell-role">{operator.role}</div>
            <div className="operator-cell-status">{operator.register_status}</div>
            {/* <div className="operator-cell operator-delete-cell">
                <img
                    src={delIcon}
                    alt="Delete"
                    className={`delIcon ${isModalOpen ? 'disabled-hover' : ''}`}
                    onClick={handleDeleteClick}
                />
            </div> */}

            {isModalOpen && (
                <div className="modal-overlay-delete-confirmation">
                    <div className="modal-content-delete-confirmation">
                        <div className="modal-header-delete-confirmation">
                            <button className="modal-close-button-delete" onClick={cancelDelete}>×</button>
                            <img
                                src={cross}
                                alt="Character"
                                className="modal-cross-icon-delete"
                            />
                        </div>
                        <h2>Are you sure?</h2>
                        <p>Do you really want to delete the provider? This process cannot be undone.</p>
                        <div className="modal-actions-delete-confirmation">
                            <button className="modal-confirm-button-delete" onClick={(e) => confirmDelete(operator.email, e)}>
                                Okay
                            </button>
                            {/* <button className="modal-cancel-button-delete" onClick={cancelDelete}>
                                Cancel
                            </button> */}
                        </div>
                    </div>
                </div>
            )}

            {isSuccessModalOpen && (
                <div className="confirmation-modal-overlay">
                    <div className="confirmation-modal-content">
                        <div className="confirmation-modal-header">
                            <img
                                src={physician_thumbsup_image}
                                alt="Character"
                                className="check-image"
                            />
                        </div>
                        <h2>Great!</h2>
                        <p>The provider has been successfully deleted.</p>
                        <button onClick={closeSuccessModal}>Okay</button>
                    </div>
                </div>
            )}

            {isErrorModalOpen && (
                <div className="error-modal-overlay">
                    <div className="error-modal-content">
                        <div className="error-modal-header">
                            <img
                                src={cross}
                                alt="Character"
                                className="cross-image"
                            />
                        </div>
                        <h2>Oh Snap!</h2>
                        <p>There was an error deleting the provider. Please try again later.</p>
                        <div className="error-modal-actions">
                            <button className="error-ok-button" onClick={closeErrorModal}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OperatorRow;
