import React, { useState } from 'react';
import './PatientRow.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import delIcon from "../Images/weui_delete-filled.png";
import cross from "../Images/fluent_warning-32-regular.png";
import physician_thumbsup_image from "../Images/simple-line-icons_check.png";

const PatientRow = ({ patient, handleGetPatients }) => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const handleRowClick = () => {
        sessionStorage.setItem('Patient', JSON.stringify(patient));
        navigate('/profile', { state: { patient } });
    };

    const getToken = () => {
        const role = sessionStorage.getItem('role');
        return role === 'Physician'
            ? sessionStorage.getItem('operator_token')
            : sessionStorage.getItem('admin_token');
    };

    const handleDeleteClick = (e) => {
        e.stopPropagation(); // Prevent row click from triggering
        setIsModalOpen(true);
    };

    const confirmDelete = async (e) => {
        e.stopPropagation();

        try {
            const token = getToken();
            const project = sessionStorage.getItem('project');

            const response = await axios.delete(`/operator/delete_patient/${patient.email}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    project: project,
                },
            });

            if (response.status === 200) {
                console.log("Patient deleted successfully!");
                setIsModalOpen(false);
                setIsSuccessModalOpen(true);
                try {
                    handleGetPatients(); // Refresh the patient list
                } catch (err) {
                    console.error("Error refreshing patient list:", err);
                }
            } else {
                setIsModalOpen(false);
                setIsErrorModalOpen(true);
            }

        } catch (error) {
            console.error("Error deleting patient:", error);
            setIsModalOpen(false);
            setIsErrorModalOpen(true);
        }
    };

    const cancelDelete = (e) => {
        e.stopPropagation();
        setIsModalOpen(false);
    };

    const closeSuccessModal = (e) => {
        e.stopPropagation();
        setIsSuccessModalOpen(false);
    };

    const closeErrorModal = (e) => {
        e.stopPropagation();
        setIsErrorModalOpen(false);
    };

    return (
        <>
            <div className="patient-row" onClick={handleRowClick} style={{ cursor: 'pointer' }}>
                <div className="patient-cell">{patient.mrn_number}</div>
                <div className="patient-cell">{patient.first_name}</div>
                <div className="patient-cell">{patient.last_name}</div>
                <div className="patient-cell">{patient.email}</div>
                <div className="patient-cell">
                    {patient.indication && Array.isArray(patient.indication)
                        ? patient.indication.join(', ')
                        : ""}
                </div>
                {/* <div className="patient-cell patient-delete-cell">
                    <img
                        src={delIcon}
                        alt="Delete"
                        className="delete-icon"
                        onClick={handleDeleteClick}
                    />
                </div> */}
            </div>

            {isModalOpen && (
                <div className="modal-overlay-delete-confirmation">
                    <div className="modal-content-delete-confirmation">
                        <div className="modal-header-delete-confirmation">
                            <button className="modal-close-button-delete" onClick={cancelDelete}>×</button>
                            <img src={cross} alt="Warning" className="modal-cross-icon-delete" />
                        </div>
                        <h2>Are you sure?</h2>
                        <p>Do you really want to delete this patient? This action cannot be undone.</p>
                        <div className="modal-actions-delete-confirmation">
                            <button className="modal-confirm-button-delete" onClick={confirmDelete}>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isSuccessModalOpen && (
                <div className="confirmation-modal-overlay">
                    <div className="confirmation-modal-content">
                        <div className="confirmation-modal-header">
                            <img src={physician_thumbsup_image} alt="Success" className="check-image" />
                        </div>
                        <h2>Success!</h2>
                        <p>The patient has been successfully deleted.</p>
                        <button onClick={closeSuccessModal}>Okay</button>
                    </div>
                </div>
            )}

            {isErrorModalOpen && (
                <div className="error-modal-overlay">
                    <div className="error-modal-content">
                        <div className="error-modal-header">
                            <img src={cross} alt="Error" className="cross-image" />
                        </div>
                        <h2>Error!</h2>
                        <p>Failed to delete the patient. Please try again later.</p>
                        <button onClick={closeErrorModal}>Close</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default PatientRow;
