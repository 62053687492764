import React, { useState, useEffect } from 'react';
import './InvitePatientModal.css';
import patient_physician_image from "../Images/mindy_clipboard_physician 5.png";
import physician_thumbsup_image from "../Images/simple-line-icons_check.png";
import cross from "../Images/cross.png";
import axios from "axios";

const InvitePatientModal = ({ open, onClose }) => {
    const [formValues, setFormValues] = useState({
        firstName: '',
        lastName: '',
        email: '',
        mrnNumber: '',
        physicianName: '',
        providerId: ''
    });
    const [isClicked, setIsClicked] = useState(false);
    const role = sessionStorage.getItem("role")
    const [physicians, setPhysicians] = useState([]);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
    const [isErrorOpen, setIsErrorOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedPhysician, setSelectedPhysician] = useState('');

    const getToken = () => {
        const token =
            role === "Physician" || role === "MRI-Tech" || role === "Nurse"
                ? sessionStorage.getItem("operator_token")
                : sessionStorage.getItem("admin_token");

        return token;

    };
    const handlePhysicianSelect = (physician) => {
        const fullName = `${physician.first_name} ${physician.last_name}`;
        setSelectedPhysician(fullName);

        setFormValues((prevValues) => ({
            ...prevValues,
            physicianName: fullName,
            providerId: physician.provider_id,
        }));
        setIsDropdownOpen(false);
    };


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    };
    const resetForm = () => {
        setFormValues({
            firstName: '',
            lastName: '',
            email: '',
            mrnNumber: '',
            physicianName: '',
            providerId: ''
        });
        setSelectedPhysician('');
        setIsDropdownOpen(false);
    };

    useEffect(() => {
        if (open) {
            handleGetPhysicians();
        }
    }, [open]);

    const handleSendInvitationEmail = async (e) => {
        e.preventDefault();
        setIsClicked(true);
        try {
            const token = getToken();
            const project = sessionStorage.getItem("project");
            const response = await axios.post(
                `/operator/send_invitation_email/${formValues.email}`,
                formValues,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        project: project,
                        role
                    },
                }
            );

            if (response.status === 200) {
                setIsConfirmationOpen(true);
                resetForm();
            } else {
                setErrorMessage(`Failed to add patient. Status: ${response.status}`);
                setIsErrorOpen(true);
            }
        } catch (error) {
            setErrorMessage("An error occurred while sending the invitation. Please try again.");
            setIsErrorOpen(true);
            console.error("Error adding patient:", error);
        }
    };

    const handleGetPhysicians = async (e) => {

        try {
            const token = getToken();
            const project = sessionStorage.getItem("project");
            const response = await axios.get(
                `/operator/get_all_physicians/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        project: project,
                        role
                    },
                }
            );

            if (response.status === 200) {
                setPhysicians(response.data.operators);
            } else {
                setErrorMessage(`Failed to fetch physicians. Status: ${response.status}`);
                setIsErrorOpen(true);
            }
        } catch (error) {
            setErrorMessage("An error occurred while fetching the physicians. Please try again.");
            setIsErrorOpen(true);
            console.error("Error adding patient:", error);
        }
    };

    const handleCancel = () => {
        resetForm(); // Reset form on cancel
        onClose(); // Close the modal
    };

    const handleConfirmationClose = () => {
        resetForm(); // Reset form when confirmation modal is closed
        setIsConfirmationOpen(false);
        onClose();
    };

    const handleErrorClose = () => {
        setIsErrorOpen(false);
        // onClose();
    };

    if (!open) return null; // Don't render if modal is closed

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="modal-close-button" data-testid="modal-close-button" onClick={handleCancel}>
                    &times;
                </button>
                <div className='invite-patient-form'>
                    <h2>Invite New Patient</h2>
                    <p>Enter the patient's details below to send them an invitation.</p>
                    <form onSubmit={handleSendInvitationEmail}>
                        <div className="form-group">
                            <div className="form-field-firstName">
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    type="text"
                                    id="firstName"
                                    name="firstName"
                                    value={formValues.firstName}
                                    onChange={handleChange}
                                    placeholder="First name"
                                    className="firstname-form"
                                    required
                                />
                            </div>
                            <div className="form-field-lastName">
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    type="text"
                                    id="lastName"
                                    name="lastName"
                                    value={formValues.lastName}
                                    onChange={handleChange}
                                    placeholder="Last name"
                                    className="firstname-form"
                                    required
                                />
                            </div>
                        </div>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formValues.email}
                            onChange={handleChange}
                            placeholder="Email"
                            className="firstname-form"
                            required
                        />
                        <label htmlFor="mrnNumber">MRN Number</label>
                        <input
                            type="text"
                            id="mrnNumber"
                            name="mrnNumber"
                            value={formValues.mrnNumber}
                            onChange={handleChange}
                            placeholder="MRN Number"
                            className="firstname-form"
                            required
                        />
                        <label htmlFor="physicianName">Physician</label>
                        <div
                            className="custom-dropdown"
                            tabIndex="0"
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    setIsDropdownOpen(!isDropdownOpen);
                                } else if (e.key === "ArrowDown" && isDropdownOpen) {
                                    document.querySelector(".dropdown-list li").focus();
                                }
                            }}
                        >
                            <div
                                className="dropdown-header-invite-patient"
                                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                            >
                                {selectedPhysician || "Select Physician"}
                                <span className={`arrow ${isDropdownOpen ? "open" : ""}`}>▼</span>
                            </div>

                            {isDropdownOpen && (
                                <ul className="dropdown-list">
                                    {physicians.map((physician, index) => (
                                        <li
                                            key={physician.provider_id}
                                            onClick={() => handlePhysicianSelect(physician)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    handlePhysicianSelect(physician);
                                                    setIsDropdownOpen(false);
                                                } else if (e.key === "ArrowDown" && index < physicians.length - 1) {
                                                    document.querySelectorAll(".dropdown-list li")[index + 1].focus();
                                                } else if (e.key === "ArrowUp" && index > 0) {
                                                    document.querySelectorAll(".dropdown-list li")[index - 1].focus();
                                                }
                                            }}
                                            tabIndex="0"
                                            className={`dropdown-item ${selectedPhysician ===
                                                `${physician.first_name} ${physician.last_name}`
                                                ? "selected"
                                                : ""
                                                }`}
                                        >
                                            {physician.first_name} {physician.last_name}
                                        </li>
                                    ))}
                                </ul>
                            )}

                            <select
                                id="physicianName"
                                name="physicianName"
                                value={formValues.physicianName}
                                onChange={handleChange}
                                style={{
                                    position: "absolute",
                                    left: "-9999px",
                                    width: "1px",
                                    height: "1px",
                                    overflow: "hidden",
                                }}
                                tabIndex="-1"
                            >
                                {physicians.map((physician) => (
                                    <option key={physician.provider_id} value={physician.provider_id}>
                                        {physician.first_name} {physician.last_name}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button type="submit" className="invite-button">
                            Invite
                        </button>
                    </form>



                </div>
                <img
                    src={patient_physician_image}
                    alt="Character"
                    className="corner-image"
                />
            </div>

            {isConfirmationOpen && (
                <div className="confirmation-modal-overlay">
                    <div className="confirmation-modal-content">
                        <div className="confirmation-modal-header">
                            <img
                                src={physician_thumbsup_image}
                                alt="Character"
                                className="check-image"
                            />
                        </div>
                        <h2>Great!</h2>
                        <p>Invite sent successfully!</p>
                        <button onClick={handleConfirmationClose}>Okay</button>
                    </div>
                </div>

            )}

            {isErrorOpen && (
                <div className="error-modal-overlay">
                    <div className="error-modal-content">
                        <div className="error-modal-header">
                            <img
                                src={cross}
                                alt="Character"
                                className="cross-image"
                            />
                            {/* <button className="modal-close-button" onClick={handleErrorClose}>
                                &times;
                            </button> */}
                        </div>
                        <h2>Oh Snap!</h2>
                        <p>{errorMessage}</p>
                        <div className="error-modal-actions">
                            <button className="error-ok-button" onClick={handleErrorClose}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InvitePatientModal;
