import React, { useState, useEffect } from "react";
import "./NavBarNew.css";
import mindyHello from "../../Images/logo.png";
import { FaSignInAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import { FcGoogle } from "react-icons/fc";
import { useNavigate, NavLink } from "react-router-dom";
import cross from "../../Images/cross.png";

const NavbarNew = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [role, setRole] = useState("");
  const navigate = useNavigate();
  let userRole;
  let project;

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      fetch_email(codeResponse);
      setUser(codeResponse);
      console.log("Google login success, codeResponse");
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      setError("Google Login failed. Please try again.");
      setIsErrorOpen(true);
    },
  });
  const fetch_email = async (user) => {
    if (!user) return;

    try {
      const authResponse = await axios.post("common/api/verify", {
        token: user.access_token,
      });

      if (authResponse.status === 200) {
        console.log("Backend email verification success:");
        const project = authResponse.data.project;
        const userRole = authResponse.data.role;

        if (!userRole) {
          throw new Error("Role is missing or invalid");
        }

        setRole(userRole);

        sessionStorage.setItem("project", project);
        sessionStorage.setItem("role", userRole);

        authenticateAndFetchProfile(user, userRole);
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Error during authentication or profile fetch:", error);
      setError("Authentication or profile fetch failed. Please try again.");
      setIsErrorOpen(true);
    }
  };

  let apiEndpoint;
  const authenticateAndFetchProfile = async (user, userRole) => {
    const project = sessionStorage.getItem("project");

    try {
      apiEndpoint =
        userRole === "Physician" ||
        userRole === "MRI-Tech" ||
        userRole === "Nurse"
          ? "/operator/api/authenticate"
          : userRole === "Admin"
          ? "/admin/check_admin_auth"
          : userRole === "Patient"
          ? "/patient/api/authenticate"
          : null;

      if (!apiEndpoint) return;

      const authResponse = await axios.post(
        apiEndpoint,
        { token: user.access_token },
        { headers: { project } }
      );

      if (authResponse.status === 200) {
        const token = authResponse.data.access_token;
        if (
          userRole === "Physician" ||
          userRole === "MRI-Tech" ||
          userRole === "Nurse"
        ) {
          sessionStorage.setItem("operator_token", token);
        } else if (userRole === "Admin") {
          sessionStorage.setItem("admin_token", token);
        } else if (userRole === "Patient") {
          sessionStorage.setItem("patient_token", token);
        }

        // Set userObject based on the role
        let userObject;
        let patient;
        if (userRole === "Admin") {
          userObject = authResponse.data.admin;
        } else if (
          userRole === "Physician" ||
          userRole === "MRI-Tech" ||
          userRole === "Nurse"
        ) {
          userObject = authResponse.data.operator;
        } else if (userRole === "Patient") {
          patient = authResponse.data.patient;
          userObject = authResponse.data.patient;
        }

        // Store userObject in session storage
        if (userObject) {
          sessionStorage.setItem("userObject", JSON.stringify(userObject));
          if (userRole === "Patient") {
            navigate("/profile", { state: { patient } });
          } else {
            navigate(`/operatorPatientlist`, { state: { userObject } });
          }
        }
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      setError("Authentication or profile fetch failed. Please try again.");
      setIsErrorOpen(true);
    }
  };

  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  // useEffect(() => {
  //     if (!isInitialLoad) {
  //         authenticateAndFetchProfile(user, navigate);
  //     }
  //     setIsInitialLoad(false);
  // }, [user, navigate]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="navbar">
      <Link to="/">
        <div className="logo">
          <img src={mindyHello} alt="mindyhello" className="logo-image" />
        </div>
      </Link>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        ☰ Menu
      </button>

      <ul className={`navbar-links ${isOpen ? "show" : ""}`}>
        <li>
          <NavLink
            to="/physicians"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Provider
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/patients"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            Patients
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/aboutus"
            className={({ isActive }) => (isActive ? "active-link" : "")}
          >
            About Us
          </NavLink>
        </li>
      </ul>

      {/* <button className="sign-in-button">
                Sign In <FaSignInAlt className="sign-in-icon" />
            </button> */}
      <button className="sign-in-button" onClick={() => login()}>
        <img
          src="https://developers.google.com/identity/images/g-logo.png"
          alt="Google Logo"
          className="google-logo"
        />
        Sign In <FaSignInAlt className="sign-in-icon" />
      </button>
      {isErrorOpen && (
        <div className="error-modal-overlay">
          <div className="error-modal-content">
            <div className="error-modal-header">
              <img src={cross} alt="Error" className="cross-image" />
            </div>
            <h2>Oh Snap!</h2>
            <p>{error}</p>
            <button className="error-ok-button" onClick={handleErrorClose}>
              Ok
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default NavbarNew;
