import React from "react";
import "./HeroSectionPhysician.css";

const HeroSectionAboutUs = () => {
  return (
    <div className="hero-section">
      <div className="gradient-overlay"></div>
      <div className="content-overlay">
        <h1>neuro42</h1>
        <p>
          Revolutionizing brain health with portable and advanced MRI
          technology.
        </p>
      </div>
    </div>
  );
};

export default HeroSectionAboutUs;
