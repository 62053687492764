import React, { useEffect, useState } from "react";
import "../Operator/OperatorScanListNew.css";
import "./AdminScanRequestList.css";
import NavbarNew from "../Navbar/NavbarNew";
import Footer from "../Operator/Footer";
import Pagination from "@mui/material/Pagination";
import questionMark from "../Images/Vector.png";
import whiteTick from "../Images/White-tick.png";
import Tick from "../Images/mdi_approve.png";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Sidebar from "../Operator/components/Sidebar";
import axios from "axios";
import SidebarNew from "../Operator/components/SidebarNew";
import useIdleTimer from "../../useIdleTimer";
import cross from "../Images/cross.png";
import log from "../../logger.js"



const AdminScanRequestList = () => {
  const handleIdle = () => {
    console.log("User is idle, logging out...");
    window.location.href = "/";
  }
  useIdleTimer(handleIdle, 1.2e6);

  const storedUserObjectString = sessionStorage.getItem("userObject");
  const role = sessionStorage.getItem("role");
  const storedUserObject = JSON.parse(storedUserObjectString);
  const username = storedUserObject.first_name;

  const [pendingRequests, setPendingRequests] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [error, setError] = useState({
    res: "",
  });

  useEffect(() => {
    loadPendingRequests();
  }, [currentPage, pageSize]);

  const getToken = () => sessionStorage.getItem("admin_token");
  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  const loadPendingRequests = async () => {
    setLoading(true);
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");

      const response = await axios.post(
        `/admin/get_admin_profile/`,
        { email: storedUserObject.email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project,
          },
        }
      );

      if (response.status === 200) {
        const allRequests = response.data.admin.pending_requests || [];
        const startIndex = (currentPage - 1) * pageSize;
        const currentRequests = allRequests.slice(
          startIndex,
          startIndex + pageSize
        );
        setPendingRequests(currentRequests);
        setTotalPages(Math.ceil(allRequests.length / pageSize));
      }
      else {
        log.warn("Failed to Load Pending Requests. Non-200 response.", {
          status_code: response.status,
          response_data: response.data,
        })
      }
    } catch (error) {
      log.error("Error fetching pending requests:", error);
      setError("Failed to fetch patients. Please try again.");
      setIsErrorOpen(true);
    } finally {
      setLoading(false);
    }
  };

  function parseDate(dateString) {
    console.log(dateString);
    if (typeof dateString !== "string" || dateString.length !== 8) {
      throw new Error("Invalid date format. Expected format: YYYYMMDD.");
    }

    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);

    if (!year || !month || !day || isNaN(year) || isNaN(month) || isNaN(day)) {
      throw new Error(
        "Invalid date components. Ensure the input is in YYYYMMDD format."
      );
    }

    return `${month}/${day}/${year}`;
  }

  const handleApproveRequest = async (request) => {
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");

      const response = await axios.post(
        `/admin/approve_scan_request/`,
        {
          email: request.email,
          firstname: request.firstname,
          lastname: request.lastname,
          studyId: request.studyId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project,
          },
        }
      );

      if (response.status === 200) {
        setPendingRequests((prevRequests) =>
          prevRequests.filter((r) => r.studyId !== request.studyId)
        );
      }
      else {
        log.error("Error occurred while Approving the request.", {
          error: error.message,
          stack: error.stack,
        });
      }
    } catch (error) {
      log.error("Error approving scan request:", error);
      setError("Failed to Approve the Scan Request. Please try again.");
      setIsErrorOpen(true);
    }
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };

  return (
    <div className="page-body">
      <NavbarNew username={username} />
      <div className="page-layout">
        <SidebarNew />
        <div className="main-content">
          <div className="patient-list-container">
            <div className="first-line">
              <div className="title-container">
                <h2 className="inner-page-title">Scan Request List</h2>
                <div className="tooltip-container">
                  <img
                    src={questionMark}
                    alt="info-icon"
                    className="info-icon"
                  />
                  <span className="tooltip-text">
                    View the scan requests here
                  </span>
                </div>
              </div>
            </div>
          </div>
          {loading ? (
            <div className="loading-spinner-overlay" data-testid="loading-spinner">
              <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          ) : (
            <div className="scan-table-container">
              <div className="scan-table-header-admin">
                <div className="header-cell">First Name</div>
                <div className="header-cell">Last Name</div>
                <div className="header-cell">Email</div>
                <div className="header-cell">Scan Date</div>
                <div className="header-cell">Approve</div>
              </div>
              <div className="table-body">
                {pendingRequests.map((request, index) => (
                  <div key={index} className="admin-user-row">
                    <div className="user-cell">{request.firstname}</div>
                    <div className="user-cell">{request.lastname}</div>
                    <div className="user-cell">{request.email}</div>
                    <div className="user-cell">
                      {parseDate(request.studyDate)}
                    </div>
                    <div className="user-cell">
                      <button
                        className="request-access-button" data-testid="Approve-Button"
                        onClick={() => handleApproveRequest(request)}
                      >
                        Approve
                      </button>
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination-container">
                <Stack spacing={2}>
                  <Pagination
                    count={totalPages}
                    siblingCount={0}
                    boundaryCount={1}
                    page={currentPage}
                    onChange={(event, page) => handlePageChange(page)}
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    sx={{
                      "& .MuiPaginationItem-root": {
                        color: "#333333",
                        fontSize: "1vw",
                        borderRadius: "8px",
                        border: "1px solid #CCCCCC",
                      },
                      "& .Mui-selected": {
                        backgroundColor: "#66A091 !important",
                        color: "#FFFFFF !important",
                        fontSize: "1vw",
                        border: "1px solid #66A091",
                      },

                      "& .MuiPaginationItem-root:hover": {
                        backgroundColor: "#66A091",
                        color: "#FFFFFF",
                      },
                      "& .MuiPaginationItem-ellipsis": {
                        color: "#666666",
                      },
                      "& .MuiPaginationItem-icon": {
                        color: "#004C40",
                      },
                    }}
                  />
                </Stack>

                <Select
                  value={pageSize}
                  onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                  variant="outlined"
                  size="small"
                  inputProps={{
                    "aria-label": "Items per page",
                  }}
                  style={{ minWidth: 60 }}
                  sx={{
                    minHeight: 3,
                    borderRadius: "10px",
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "#333333",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#000000",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#555555",
                    },
                    ".MuiSelect-select": {
                      padding: "8px 10px",
                      fontSize: "var(--font-size-l)",
                      color: "#000000",
                    },
                    ".MuiSelect-root": {
                      minHeight: "unset",
                    },
                  }}
                >
                  <MenuItem value={10}>10 per page</MenuItem>
                  <MenuItem value={25}>25 per page</MenuItem>
                  <MenuItem value={50}>50 per page</MenuItem>
                </Select>

              </div>
            </div>
          )}
        </div>
      </div>
      {isErrorOpen && (
        <div className="error-modal-overlay">
          <div className="error-modal-content">
            <div className="error-modal-header">
              <img src={cross} alt="Error" className="cross-image" />
            </div>
            <h2>Oh Snap!</h2>
            <p>{error}</p>
            <button className="error-ok-button" onClick={handleErrorClose}>
              Ok
            </button>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default AdminScanRequestList;
