import React from "react";
import "../Operator/OperatorPatientListNew2.css";
import "./OperatorList.css";
import NavbarNew from "../Navbar/NavbarNew";
import questionMark from "../Images/Vector.png";
import search from "../Images/Search.png";
import { FaArrowUp } from "react-icons/fa";
import OperatorRow from "./OperatorRow";
import AddIcon from "../Images/Add Icon.png";
import AddIconWhite from "../Images/Add Icon white.png";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InviteProviderModal from "./InviteProviderModal";
import Footer from "../Operator/Footer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useIdleTimer from "../../useIdleTimer";
import axios from "axios";
import _ from 'lodash';


const OperatorList = () => {
    const handleIdle = () => {
        console.log("User is idle, logging out...");
        window.location.href = "/";
    }
    useIdleTimer(handleIdle, 1.2e6);
    const storedUserObjectString = sessionStorage.getItem("userObject");
    const storedUserObject = JSON.parse(storedUserObjectString);
    const username = storedUserObject.first_name;


    const [operators, setOperators] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [error, setError] = useState({
        email: "",
        res: "",
        first_name: "",
        last_name: "",
    });

    // Pagination state
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalPages, setTotalPages] = useState(1);
    const navigate = useNavigate();

    const getToken = () => {
        const token = sessionStorage.getItem("admin_token");
        return token;
    };

    const handleGetOperators = async () => {
        try {
            const token = getToken();
            if (!token) {
                navigate("/physician");
            }
            const project = sessionStorage.getItem("project");

            const response = await axios.get("/admin/get_all_operators/", {
                headers: {
                    Authorization: `Bearer ${token}`,
                    project: project,
                },
                params: {
                    page: currentPage,
                    page_size: pageSize,
                    searchQuery: searchQuery,
                },
            });

            if (response.status === 200) {
                setOperators(response.data.operators);
                setTotalPages(response.data.total_pages);
            } else {
                setError({
                    res: "Failed to fetch operators. Status: " + response.status,
                });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                const refreshResponse = await axios.post(
                    "/operator/refresh/",
                    {},
                    { withCredentials: true }
                );

                if (refreshResponse.status === 200) {
                    sessionStorage.setItem(
                        "admin_token",
                        refreshResponse.data.access_token
                    );
                    await handleGetOperators();
                } else {
                    console.error("Refresh token failed", error);
                    handleLogout();
                }
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleGetOperators();
    }, [currentPage, pageSize, searchQuery]);

    const debouncedHandleSearch = _.debounce((value) => {
        if (value.trim() === "") {
            setSearchQuery(null);
            handleGetOperators();
        } else {
            setSearchQuery(value.trim());
        }
        handleGetOperators();
    }, 300);

    const handleSearch = (value) => {
        debouncedHandleSearch(value);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
        handleGetOperators();
    };

    const handlePageSizeChange = (size) => {
        setPageSize(size);
        setCurrentPage(1);
    };

    const handleLogout = async () => {
        await axios.post("/operator/logout/", {}, { withCredentials: true });
        localStorage.clear();
        sessionStorage.clear();
    };

    const [icon, setIcon] = useState(AddIcon);
    const [textColor, setTextColor] = useState("#2A6E62");
    const [backgroundColor, setBackgroundColor] = useState("#E6F6F4");

    const handleMouseEnter = () => {
        setIcon(AddIconWhite);
        setTextColor("#FFFFFF");
        setBackgroundColor("#409E8D");
    };

    const handleMouseLeave = () => {
        setIcon(AddIcon);
        setTextColor("#2A6E62");
        setBackgroundColor("#E6F6F4");
    };

    const buttonStyle = {
        display: "flex",
        alignItems: "center",
        height: "4vh",
        justifyContent: "center",
        width: "auto",
        minWidth: "10vw",
        padding: "1px 5px",
        fontSize: "0.9vw",
        cursor: "pointer",
        marginLeft: "auto",
        marginRight: "0%",
        background: backgroundColor,
        mixBlendMode: "normal",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "2vw",
        fontFamily: "'Plus Jakarta Sans', sans-serif",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: "2vw",
        color: textColor,
        border: "none",
        transition: "color 0.3s ease",
    };

    const iconStyle = {
        width: "calc(1.5vh + 0.5vw)",
        height: "calc(1.5vh + 0.5vw)",
        marginLeft: "0.5vw",
        transition: "opacity 0.3s ease",
    };

    return (
        <>
            <NavbarNew username={username} />
            <div className="main-content">
                <div className="patient-list-container-main">
                    <div class="patient-list-title-container">
                        <h2 className="title-patient-list" data-testid="Provider-List">Provider List</h2>
                        <div class="patient-list-tooltip-container">
                            <img
                                src={questionMark}
                                alt="info-icon"
                                class="patient-list-info-icon"
                            />
                            <span class="patient-list-tooltip-text">
                                View the provider list here
                            </span>
                        </div>
                    </div>

                    <div className="patient-list-search-container">
                        <img
                            src={search}
                            alt="search"
                            className="patient-list-search-icon"
                        />
                        <input
                            type="search"
                            className="patient-list-search-input"
                            placeholder="Search Operators..."
                            value={searchQuery}
                            onChange={(e) => handleSearch(e.target.value)}
                            aria-label="Search Patients"
                        />
                    </div>

                    <button
                        className="add-patient-button-new"
                        onClick={handleOpenModal}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={buttonStyle}
                    >
                        Add Provider
                        <img
                            src={icon}
                            alt="AddIcon"
                            className="add-icon"
                            style={iconStyle}
                        />
                    </button>
                </div>

                <div className="patient-list-patient-table-container">
                    {loading && (
                        <div className="loading-spinner-overlay" data-testid="Loading-Spinner">
                            <div className="bouncing-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    )}
                    <div className="operator-list-table-header">
                        <div className="operator-list-header-cell">Provider Id</div>
                        <div className="operator-list-header-cell">First Name</div>
                        <div className="operator-list-header-cell">Last Name</div>
                        <div className="operator-list-header-cell">Email Address</div>
                        <div className="operator-list-header-cell-role">Role</div>
                        <div className="operator-list-header-cell-status">Status</div>
                    </div>
                    <div className="operator-list-table-body">
                        {operators.map((operator, index) => (
                            <OperatorRow
                                key={index}
                                operator={operator}
                                handleGetOperators={handleGetOperators}
                            />
                        ))}
                    </div>

                    {/* Pagination Section */}
                    <div className="pagination-container">
                        <Stack spacing={2}>
                            <Pagination
                                count={totalPages}
                                siblingCount={0}
                                boundaryCount={1}
                                page={currentPage}
                                onChange={(event, page) => handlePageChange(page)}
                                shape="rounded"
                                showFirstButton
                                showLastButton
                                sx={{
                                    "& .MuiPaginationItem-root": {
                                        color: "#333333",
                                        fontSize: "1vw",
                                        borderRadius: "8px",
                                        border: "1px solid #CCCCCC",
                                    },
                                    "& .Mui-selected": {
                                        backgroundColor: "#66A091 !important",
                                        color: "#FFFFFF !important",
                                        fontSize: "1vw",
                                        border: "1px solid #66A091",
                                    },

                                    "& .MuiPaginationItem-root:hover": {
                                        backgroundColor: "#66A091",
                                        color: "#FFFFFF",
                                    },
                                    "& .MuiPaginationItem-ellipsis": {
                                        color: "#666666",
                                    },
                                    "& .MuiPaginationItem-icon": {
                                        color: "#004C40",
                                    },
                                }}
                            />
                        </Stack>

                        <Select
                            value={pageSize}
                            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                            variant="outlined"
                            size="small"
                            inputProps={{
                                "aria-label": "Items per page",
                            }}
                            style={{ minWidth: 60 }}
                            sx={{
                                minHeight: 3,
                                borderRadius: "10px",
                                backgroundColor: "#ffffff",
                                color: "#000000",
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#333333",
                                },
                                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#000000",
                                },
                                "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: "#555555",
                                },
                                ".MuiSelect-select": {
                                    padding: "8px 10px",
                                    fontSize: "var(--font-size-l)",
                                    color: "#000000",
                                },
                                ".MuiSelect-root": {
                                    minHeight: "unset",
                                },
                            }}
                        >
                            <MenuItem value={10}>10 per page</MenuItem>
                            <MenuItem value={25}>25 per page</MenuItem>
                            <MenuItem value={50}>50 per page</MenuItem>
                        </Select>

                    </div>
                </div>
                <InviteProviderModal open={isModalOpen} onClose={handleCloseModal} />
            </div>
            <Footer />
        </>
    );
};

export default OperatorList;
