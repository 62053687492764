import React, { useState } from "react";
import "./footer.css";
import mindyHello from "../../Images/logo.png";
import instagram from "../../Images/image 6.png";
import linkedin from "../../Images/image 5.png";
import facebook from "../../Images/image 4.png";
import twitter from "../../Images/image 3.png";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Footer = () => {
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [error, setError] = useState("");
  const [user, setUser] = useState([]);
  const [role, setRole] = useState("");
  const navigate = useNavigate();

  const login = useGoogleLogin({
    onSuccess: (codeResponse) => {
      fetch_email(codeResponse);
      setUser(codeResponse);
      console.log("Google login success, codeResponse");
    },
    onError: (error) => {
      console.error("Login Failed:", error);
      setError("Google Login failed. Please try again.");
      setIsErrorOpen(true);
    },
  });
  const authenticateAndFetchProfile = async (user, userRole) => {
    const project = sessionStorage.getItem("project");

    try {
      apiEndpoint =
        userRole === "Physician" ||
        userRole === "MRI-Tech" ||
        userRole === "Nurse"
          ? "/operator/api/authenticate"
          : userRole === "Admin"
          ? "/admin/check_admin_auth"
          : userRole === "Patient"
          ? "/patient/api/authenticate"
          : null;

      if (!apiEndpoint) return;

      const authResponse = await axios.post(
        apiEndpoint,
        { token: user.access_token },
        { headers: { project } }
      );

      if (authResponse.status === 200) {
        const token = authResponse.data.access_token;
        if (
          userRole === "Physician" ||
          userRole === "MRI-Tech" ||
          userRole === "Nurse"
        ) {
          sessionStorage.setItem("operator_token", token);
        } else if (userRole === "Admin") {
          sessionStorage.setItem("admin_token", token);
        } else if (userRole === "Patient") {
          sessionStorage.setItem("patient_token", token);
        }

        // Set userObject based on the role
        let userObject;
        let patient;
        if (userRole === "Admin") {
          userObject = authResponse.data.admin;
        } else if (
          userRole === "Physician" ||
          userRole === "MRI-Tech" ||
          userRole === "Nurse"
        ) {
          userObject = authResponse.data.operator;
        } else if (userRole === "Patient") {
          patient = authResponse.data.patient;
          userObject = authResponse.data.patient;
        }

        // Store userObject in session storage
        if (userObject) {
          sessionStorage.setItem("userObject", JSON.stringify(userObject));
          if (userRole === "Patient") {
            navigate("/profile", { state: { patient } });
          } else {
            navigate(`/operatorPatientlist`, { state: { userObject } });
          }
        }
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Error during authentication:", error);
      setError("Authentication or profile fetch failed. Please try again.");
      setIsErrorOpen(true);
    }
  };
  let apiEndpoint;
  const fetch_email = async (user) => {
    if (!user) return;

    try {
      const authResponse = await axios.post("common/api/verify", {
        token: user.access_token,
      });

      if (authResponse.status === 200) {
        console.log("Backend email verification success:");
        const project = authResponse.data.project;
        const userRole = authResponse.data.role;

        if (!userRole) {
          throw new Error("Role is missing or invalid");
        }

        setRole(userRole);

        sessionStorage.setItem("project", project);
        sessionStorage.setItem("role", userRole);

        authenticateAndFetchProfile(user, userRole);
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Error during authentication or profile fetch:", error);
      setError("Authentication or profile fetch failed. Please try again.");
      setIsErrorOpen(true);
    }
  };
  return (
    <footer class="footer-main">
      <div class="footer-container">
        <div class="footer-top">
          <div class="footer-logo-social">
            <img src={mindyHello} alt="Logo" class="footer-logo" />
            <div class="footer-social">
              <a href="#">
                <img src={twitter} alt="Twitter" />
              </a>
              <a href="#">
                <img src={facebook} alt="Facebook" />
              </a>
              <a href="#">
                <img src={linkedin} alt="LinkedIn" />
              </a>
              <a href="#">
                <img src={instagram} alt="Instagram" />
              </a>
            </div>
          </div>

          <div class="footer-divider"></div>
          <div class="footer-nav">
            <ul>
              <li>
                <a href="/physicians">Provider</a>
              </li>
              <li>
                <a href="/patients">Patients</a>
              </li>
              <li>
                <a href="/aboutus">About Us</a>
              </li>
              <li>
                <button
                  type="button"
                  onClick={() => login()}
                  style={{
                    background: "none",
                    border: "none",
                    color: "inherit",
                    cursor: "pointer",
                  }}
                >
                  Sign In
                </button>
              </li>
            </ul>
          </div>
          <div class="footer-contact" data-testid="footer-contact">
            <p>Contact Us</p>
            <p>
              (480) 241-3211
              <br />
              hello@neuro42.com
            </p>
          </div>
          <div class="footer-address" data-testid="footer-address">
            <p>
              2 Bryant St, Suite 240
              <br />
              San Francisco, CA 94105
            </p>
          </div>
        </div>
        <div class="footer-bottom">
          <p>©2024 neuro42 Health All rights reserved.</p>
          <ul>
            <li>
              <a href="/privacypolicy">Privacy Policy</a>
            </li>
            <li>
              <a href="/termsandconditions">Terms and Conditions</a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
