import React, { useState, useEffect } from "react";
import "./FeedbackOperator.css";
import patient_physician_image from "../Images/mindy_waving_admin.png";
import physician_thumbsup_image from "../Images/simple-line-icons_check.png";
import happy from "../Images/cil_happy.png";
import sad from "../Images/cil_sad.png";
import cross from "../Images/cross.png";
import axios from "axios";

const FeedbackOperator = ({ open, onClose }) => {
  const storedUserObjectString = sessionStorage.getItem("userObject");

  const storedUserObject = JSON.parse(storedUserObjectString);
  const email = storedUserObject.email;
  const last_name = storedUserObject.last_name;
  const username = storedUserObject.first_name;
  const role = sessionStorage.getItem("role");
  const project = sessionStorage.getItem("project");
  const [formValues, setFormValues] = useState({
    feedback_type: "",
    feedback_comments: "",
  });
  const [isClicked, setIsClicked] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    // { value: '', label: 'Select the type of Feedback' },
    { value: "UI Experience", label: "UI Experience" },
    { value: "Scan Visibility", label: "Scan Visibility" },
    { value: "Performance", label: "Performance" },
    { value: "Content Quality", label: "Content Quality" },
    { value: "Functionality", label: "Functionality" },
    { value: "Accessibility", label: "Accessibility" },
    { value: "Mobile Experience", label: "Mobile Experience" },
    { value: "Security", label: "Security" },
    { value: "Customer Support", label: "Customer Support" },
    { value: "Browser Compatibility", label: "Browser Compatibility" },
    { value: "Overall Satisfaction", label: "Overall Satisfaction" },
  ];

  const handleOptionClick = (value) => {
    setSelectedOption(value);
    handleChange({ target: { name: "feedback_type", value } }); // Simulating select behavior
    setIsOpen(false);
  };

  const getToken = () => {
    const role = sessionStorage.getItem("role");

    return sessionStorage.getItem(
      role === "Admin"
        ? "admin_token"
        : role === "MRI-Tech" || role === "Physician" || role === "Nurse"
          ? "operator_token"
          : role === "Patient"
            ? "patient_token"
            : null
    );
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSendFeedback = async (e) => {
    e.preventDefault();
    setIsClicked(true);
    const data = {
      email: email,
      last_name: last_name,
      username: username,
      role: role,
      project_id: project,
      feedback_type: formValues.feedback_type,
      feedback_comments: formValues.feedback_comments,
      experience: formValues.experience,
      upload_date: new Date().toISOString(),
    };

    try {
      const token = getToken();
      const response = await axios.post(`/operator/submit_feedback/`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          project: project,
        },
      });

      if (response.status === 200 || response.status === 201) {
        setIsConfirmationOpen(true);
      } else {
        setErrorMessage(`Failed to save feedback. Status: ${response.status}`);
        setIsErrorOpen(true);
      }
    } catch (error) {
      setErrorMessage(
        "An error occurred while saving the feedback. Please try again."
      );
      setIsErrorOpen(true);
      console.error("Error adding patient:", error);
    }
  };

  const handleConfirmationClose = () => {
    setIsConfirmationOpen(false);
    onClose();
  };

  const handleErrorClose = () => {
    setIsErrorOpen(false);
    // onClose();
  };
  function toggleDropdown() {
    const dropdown = document.querySelector(".select-options");
    dropdown.style.display =
      dropdown.style.display === "block" ? "none" : "block";
  }

  function selectOption(option) {
    const selectBox = document.querySelector(".select-box");
    selectBox.textContent = option.textContent;
    toggleDropdown(); // Close dropdown after selection
  }
  const resetForm = () => {
    setSelectedOption(""); // Clear the selected option
    setFormValues({
      feedback_type: "", // Clear form feedback type
      feedback_comments: "", // Clear comments
    });
  };

  useEffect(() => {
    if (!open) {
      resetForm(); // Clear selections and form when modal is closed
    }
  }, [open]);

  if (!open) return null; // Don't render if modal is closed

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        <div className="invite-patient-form">
          <h2>Feedback</h2>
          <p>We value your feedback.</p>
          <form>
            <div className="custom-dropdown">
              <div
                className="dropdown-header"
                onClick={() => setIsOpen(!isOpen)}
              >
                {selectedOption || "Select the type of Feedback"}
                <span className={`arrow ${isOpen ? "open" : ""}`}>▼</span>
              </div>
              {isOpen && (
                <ul className="dropdown-list">
                  {options.map((option) => (
                    <li
                      key={option.value}
                      onClick={() => handleOptionClick(option.value)}
                      className={`dropdown-item ${selectedOption === option.value ? "selected" : ""
                        }`}
                    >
                      {option.label}
                    </li>
                  ))}
                </ul>
              )}
              {/* Hidden native select to store the form value */}
              <select
                name="feedback_type"
                value={formValues.feedback_type}
                onChange={handleChange}
                style={{ display: "none" }}
              >
                {options.map((option) => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="experience-line">
              <p>How was your experience?</p>
              <div className="emoji-feedback">
                <span
                  className={`emoji ${formValues.experience === "happy" ? "selected" : ""
                    }`}
                  onClick={() =>
                    setFormValues({ ...formValues, experience: "happy" })
                  }
                  role="img"
                  aria-label="happy"
                >
                  <img
                    src={happy}
                    alt="happy"
                    style={{ width: "30px", height: "30px" }}
                  />
                </span>
                <span
                  className={`emoji ${formValues.experience === "sad" ? "selected" : ""
                    }`}
                  onClick={() =>
                    setFormValues({ ...formValues, experience: "sad" })
                  }
                  role="img"
                  aria-label="sad"
                >
                  <img
                    src={sad}
                    alt="sad"
                    style={{ width: "30px", height: "30px" }}
                  />
                </span>
              </div>
            </div>

            <textarea
              name="feedback_comments"
              value={formValues.feedback_comments}
              onChange={handleChange}
              placeholder="Your Feedback"
              className="feedback_comments"
              required
            />

            <button
              type="submit"
              className="invite-button"
              onClick={handleSendFeedback}
            >
              Submit
            </button>
          </form>
        </div>
        <img
          src={patient_physician_image}
          alt="Character"
          className="corner-image-feedback"
        />
      </div>

      {/* Confirmation Modal */}
      {isConfirmationOpen && (
        <div className="confirmation-modal-overlay">
          <div className="confirmation-modal-content">
            <div className="confirmation-modal-header">
              <img
                src={physician_thumbsup_image}
                alt="Character"
                className="check-image"
              />
            </div>
            <h2>Great!</h2>
            <p>Your feedback has been submitted successfully!</p>
            <button onClick={handleConfirmationClose}>Okay</button>
          </div>
        </div>
      )}

      {/* Error Modal */}
      {isErrorOpen && (
        <div className="error-modal-overlay">
          <div className="error-modal-content">
            <div className="error-modal-header">
              <img src={cross} alt="Character" className="cross-image" />
              {/* <button className="modal-close-button" onClick={handleErrorClose}>
                                &times;
                            </button> */}
            </div>
            <h2>Oh Snap!</h2>
            <p>{errorMessage}</p>
            <div className="error-modal-actions">
              <button className="error-ok-button" onClick={handleErrorClose}>
                Ok
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FeedbackOperator;
