import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import NavbarNew from "../Navbar/NavbarNew";
import SidebarNew from "./components/SidebarNew";
import Footer from "./Footer";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import questionMark from "../Images/Vector.png";
import scan from "../Operator/SVGs/Scan.svg";
import file from "../Operator/SVGs/File.svg";
import Stack from "@mui/material/Stack";
import "./Notification.css";

const Notification = () => {
  const navigate = useNavigate();
  const storedUserObjectString = sessionStorage.getItem("userObject");
  const storedUserObject = JSON.parse(storedUserObjectString);
  const username = storedUserObject.first_name;
  const provider_id = storedUserObject.provider_id;
  const [notifications, setNotifications] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [activeTab, setActiveTab] = useState("All");
  const role = sessionStorage.getItem("role");

  const getToken = () => {
    return sessionStorage.getItem(
      role === "Admin"
        ? "admin_token"
        : role === "Operator" || role === "Physician"
          ? "operator_token"
          : role === "Patient"
            ? "patient_token"
            : null
    );
  };
  const handleGetNotification = async () => {
    try {
      const token = getToken();
      const project = sessionStorage.getItem("project");
      const response = await axios.get(
        `/operator/get_all_notifications/${provider_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project: project,
          },
          params: {
            page: currentPage,
            page_size: pageSize,
            item_type: activeTab !== "All" ? activeTab : null,
          },
        }
      );

      if (response.status === 200) {
        const notificationsData = response.data.notifications;
        setNotifications(notificationsData);
        setTotalPages(response.data.total_pages);
      } else {
        console.error(
          "Failed to fetch patients'scans. Status: " + response.status
        );
      }
    } catch (error) {
      if (error.response) {
        console.error(
          "Error fetching notifications:",
          error.response.data,
          "Status:",
          error.response.status
        );
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Error:", error.message);
      }
    }
  };
  useEffect(() => {
    handleGetNotification();
  }, [currentPage, pageSize, activeTab]);
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setCurrentPage(1);
  };
  const handleManageScanRequests = () => {
    navigate("/adminScanRequests");
  };
  return (
    <div className="page-body">
      <NavbarNew username={username} />
      <div className="page-layout">
        <SidebarNew />
        <div className="main-content">
          <div className="patient-list-container">
            <div className="first-line">
              <div className="title-container">
                <h2 className="inner-page-title">Notifications</h2>
                <div class="tooltip-container">
                  <img src={questionMark} alt="info-icon" class="info-icon" />
                  <span class="tooltip-text">View the notifications here</span>
                </div>
              </div>
            </div>
          </div>

          <div className="scan-table-container">
            <div className="notification-page-header">
              <div className="left-header-notif">
                <span
                  className={activeTab === "All" ? "active" : ""}
                  onClick={() => handleTabClick("All")}
                >
                  All
                </span>
                <span
                  className={activeTab === "file" ? "active" : ""}
                  onClick={() => handleTabClick("file")}
                >
                  Files
                </span>
                <span
                  className={activeTab === "image" ? "active" : ""}
                  onClick={() => handleTabClick("scan")}
                >
                  Scans
                </span>
                <span
                  className={activeTab === "image" ? "active" : ""}
                  onClick={() => handleTabClick("request")}
                >
                  Requests
                </span>
              </div>
            </div>

            {notifications.length === 0 ? (
              <div className="no-notifications" data-testid="no-notification">
                <p>No notifications available.</p>
              </div>
            ) : (
              <div className="table-body">
                {notifications
                  .filter(
                    (notif) =>
                      activeTab === "All" || notif.item_type === activeTab
                  )
                  .map((notif, index) => (
                    <div
                      className={
                        notif.item_type === "file"
                          ? "user-row notification-item-file"
                          : "user-row notification-item"
                      }
                      key={index}
                      onClick={
                        notif.item_type === "request"
                          ? handleManageScanRequests
                          : ""
                      }
                    >
                      {notif.item_type === "file" ? (
                        <div className="item-file">
                          <img
                            src={file}
                            alt="Scan"
                            className="sidebar-icon-notification"
                          />
                          <p className="notification-title-2" data-testid="notification-text">
                            File uploaded by{" "}
                            <span
                              style={{
                                color: "#8697D2",
                                fontWeight: "bold",
                              }}
                            >
                              {notif.patient_name}
                            </span>
                          </p>
                        </div>
                      ) : notif.item_type == "request" ? (
                        <div
                          className="item-request"
                        // onClick={handleManageScanRequests}
                        >
                          <img
                            src={scan}
                            alt="Scan"
                            className="sidebar-icon-notification"
                          />
                          <div className="notification-text">
                            <p className="notification-title-2">
                              Scan request Access raised by{" "}
                              <span
                                style={{
                                  color: "#C71585",
                                  fontWeight: "bold",
                                }}
                              >
                                {notif.patient_name}
                              </span>
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div className="item-scan">
                          <img
                            src={scan}
                            alt="Scan"
                            className="sidebar-icon-notification"
                          />
                          <div className="notification-text">
                            <p className="notification-title-2" data-testid="notification-text-scan">
                              Scan uploaded for{" "}
                              <span
                                style={{
                                  color: "#409E8D",
                                  fontWeight: "bold",
                                }}
                              >
                                {notif.patient_name}
                              </span>
                            </p>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            )}


            <div className="pagination-container">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  siblingCount={0}
                  boundaryCount={1}
                  page={currentPage}
                  onChange={(event, page) => handlePageChange(page)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "#999999", // Color of pagination items
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#F0F6F5 !important", // Custom highlight color for selected page
                      color: "#999999",
                      fontSize: "1vw", // Color of text for selected item
                    },
                    "& .MuiPaginationItem-root:hover": {
                      backgroundColor: "#F0F6F5", // Hover color
                    },
                  }}
                />
              </Stack>

              <Select
                value={pageSize}
                onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                variant="outlined"
                size="small"
                style={{ minWidth: 60 }}
                sx={{
                  minHeight: 5,
                  minHeight: 5,
                  borderRadius: "10px",
                  backgroundColor: "#f0f0f0",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#999999",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#999999",
                  },
                  ".MuiSelect-select": {
                    padding: "8px 10px",
                    fontSize: "1vw",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#999999",
                  },
                }}
              >
                <MenuItem value={10}>10 per page</MenuItem>
                <MenuItem value={25}>25 per page</MenuItem>
                <MenuItem value={50}>50 per page</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Notification;
