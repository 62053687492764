import React from "react";
import NavBar from "./NavBarNew";
import "./AboutUs.css";
import Footer from "./footer";
import { useNavigate } from "react-router-dom";
import logo from "../../Images/MRI2-removebg-preview.png";
import HeroSectionAboutUs from "./HeroSectionAboutUs";
const AboutUs = () => {
  const navigate = useNavigate();
  const handleLearnMore = () => {
    window.open("https://www.neuro42.ai/", "_blank");
  };
  const handleGetInTouch = () => {
    navigate("/ContactUsLanding");
  };
  return (
    <>
      <NavBar />
      <HeroSectionAboutUs />
      <div className="shapes">
        <div className="shape rectangle"></div>
        <div className="shape circle"></div>
      </div>

      <div className="styled-line-main">
        <div className="line1-main"></div>
        <div className="line2-main"></div>
        <div className="line3-main"></div>
      </div>

      <div className="info-section">
        <div className="info-content">
          <h2>Welcome to neuro42, Inc.!</h2>
          <p>
            neuro42, Inc. is a medtech Company advancing futuristic technologies
            for screening, diagnosis, and targeted treatment of neurological
            conditions. The Company is developing and commercializing a portable
            MRI, robotic and AI platform, backed by over 30 patents, for
            point-of-care imaging and interventions under live image guidance.
            neuro42 is founded by a team of serial entrepreneurs and innovators
            with a mission to improve lives through its patient-centric
            solutions.
          </p>
          <div className="second-section-learn-more">
            <button className="learn-more-button" onClick={handleLearnMore}>
              Learn More
            </button>
            <button className="learn-more-button" onClick={handleGetInTouch}>
              Get In Touch
            </button>
          </div>
        </div>
        <div className="info-image">
          <img src={logo} alt="MRI Device" />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default AboutUs;
