import React, { useEffect } from "react";
import "./OperatorScanListNew.css";
import NavbarNew from "../Navbar/NavbarNew";
import questionMark from "../Images/Vector.png";
import AddIcon from "../Images/Add Icon.png";
import AddIconWhite from "../Images/Add Icon white.png";
import Footer from "./Footer";
import axios from "axios";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dateIcon from "../Operator/SVGs/sort.svg";
import Sidebar from "./components/Sidebar";
import SidebarPatient from "./components/SidebarPatient";
import requestIcon from "../Images/closed-lock.png";
import useIdleTimer from "../../useIdleTimer";
import hoveredRequestIcon from "../Images/open-lock.png";
import cross from "../Images/cross.png";
import log from "../../logger.js";

const OperatorScanListNew = () => {
  const storedUserObjectString = sessionStorage.getItem("userObject");
  const role = sessionStorage.getItem("role");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const storedUserObject = JSON.parse(storedUserObjectString);
  const username = storedUserObject.first_name;

  const patient =
    role === "Patient"
      ? storedUserObject
      : JSON.parse(sessionStorage.getItem("Patient")) || {};
  const data = [
    { Date: "2024-08-30", Time: "14:25:36" },
    { Date: "2024-08-29", Time: "09:15:48" },
    { Date: "2024-08-28", Time: "18:45:12" },
    { Date: "2024-08-27", Time: "12:34:56" },
    { Date: "2024-08-26", Time: "07:22:10" },
    { Date: "2024-08-25", Time: "21:10:59" },
    { Date: "2024-08-24", Time: "16:05:33" },
    { Date: "2024-08-23", Time: "11:48:22" },
    { Date: "2024-08-22", Time: "05:30:45" },
    { Date: "2024-08-21", Time: "22:55:00" },
  ];
  const project = sessionStorage.getItem("project");
  const [scans, setScans] = useState([]);
  const [isSortedAsc, setIsSortedAsc] = useState(true);
  const [patientName, setPatientName] = useState(patient.first_name);
  const [patient_id, setPatient_id] = useState(patient.patient_id);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedScan, setSelectedScan] = useState(null);
  const [iconHover, setIconHover] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [error, setError] = useState({
    res: "",
  });

  const sort = () => {
    console.log("Sorting...");
    setIsSortedAsc(!isSortedAsc);
  };
  const handleIdle = () => {
    console.log("User is idle, logging out...");
    window.location.href = "/";
  };
  useIdleTimer(handleIdle, 1.2e6);
  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  const getToken = () => {
    return sessionStorage.getItem(
      role === "Admin"
        ? "admin_token"
        : role === "MRI-Tech" || role === "Physician" || role === "Nurse"
        ? "operator_token"
        : role === "Patient"
        ? "patient_token"
        : null
    );
  };
  if (selectedScan) {
    const dummy =
      "https://neuro42-dicom-viewer.web.app/projects/beta2-system/locations/us/datasets/neuro42-developers/dicomStores/beta1_dicom_images/study/1.2.826.0.1.3680043.10.1113.479083";
    const url = `https://neuro42-dicom-viewer.web.app/projects/beta2-system/locations/us/datasets/neuro42-developers/dicomStores/beta1_dicom_images/study/${selectedScan.seriesId};`;
    window.open(selectedScan.url);
  }
  useEffect(() => {
    handleGetPatientScan();
  }, [currentPage, pageSize]);
  const handleGetPatientScan = async () => {
    try {
      const token = getToken();
      if (!token) {
        log.warn("No token found. Redirecting to login.", {
          endpoint: "/operator/get_patient_scan_list/",
          method: "GET",
          project: project,
          response_status: 403,
          role: role,
        });
        navigate("/");
      }

      const response = await axios.get(
        `/operator/get_patient_scan_list/${patient.email}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            project: project,
          },
          params: {
            page: currentPage,
            page_size: pageSize,
          },
        }
      );

      if (response.status === 200) {
        const scansWithTimestamp = response.data.scans;
        log.info("Scans Fetched Successfully", {
          endpoint: "/operator/get_patient_scan_list/",
          method: "GET",
          project: project,
          response_status: response.status,
          role: role,
        });

        setScans(scansWithTimestamp);
        setTotalPages(response.data.total_pages);
      } else {
        log.warn("Failed to fetch Scans. Non-200 response.", {
          error: error.message,
          stack: error.stack,
          endpoint: "/operator/get_patient_scan_list/",
          method: "GET",
          project: project,
          role: role,
          response_status: response.status,
        });
        setError({
          res: "Failed to fetch patients. Status: " + response.status,
        });
        setIsErrorOpen(true);
      }
    } catch (error) {
      if (error.response) {
        log.error(error.message, {
          endpoint: "/operator/get_patient_scan_list/",
          method: "GET",
          project: project,
          role: role,
          response_status: error.status,
        });
        setError({
          res: "Failed to fetch Scans. Please try again.",
        });
        setIsErrorOpen(true);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        log.error(error.message, {
          endpoint: "/operator/get_patient_scan_list/",
          method: "GET",
          project: project,
          role: role,
          response_status: 500,
        });
        setError({ res: "Failed to fetch Scans. Please try again." });
        setIsErrorOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    handleGetPatientScan();
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  const handleDate = (dateString) => {
    const year = dateString.substring(0, 4);
    const month = dateString.substring(4, 6);
    const day = dateString.substring(6, 8);
    return `${month}-${day}-${year}`;
  };
  const handleTime = (timeString) => {
    const hour = timeString.substring(0, 2);
    const minute = timeString.substring(2, 4);
    const second = timeString.substring(4, 6);
    return `${hour}-${minute}-${second}`;
  };
  const [icon, setIcon] = useState(AddIcon);
  const [textColor, setTextColor] = useState("#409E8D");
  const [backgroundColor, setBackgroundColor] = useState("#C5ECE5");

  const [openDropdown, setOpenDropdown] = useState(null);

  // Toggle the dropdown for a specific user row
  const handleDropdownToggle = (index) => {
    console.log("data - 1");
    if (openDropdown === index) {
      console.log("1");
      setOpenDropdown(null);
      console.log("2");
    } else {
      console.log("3");
      setOpenDropdown(index);
      console.log("4");
    }
  };
  const handleMouseEnter = () => {
    setIcon(AddIconWhite);
    setTextColor("#FFFFFF");
    setBackgroundColor("#409E8D");
  };

  const handleMouseLeave = () => {
    setIcon(AddIcon);
    setTextColor("#409E8D");
    setBackgroundColor("#C5ECE5");
  };

  return (
    <div className="page-body">
      <NavbarNew username={username} />
      <div className="page-layout">
        {role === "Patient" ? <SidebarPatient /> : <Sidebar />}
        <div className="main-content">
          <div className="patient-list-container">
            <div className="first-line">
              <div className="title-container">
                <h2 className="inner-page-title">Scan List</h2>
                <div className="tooltip-container">
                  <img
                    src={questionMark}
                    alt="info-icon"
                    className="info-icon"
                  />
                  <span className="tooltip-text">View the scan list here</span>
                </div>
              </div>
              <div className="patient-values">
                <h2 className="header-value">{patientName}</h2>
                <h2 className="header-value-2">MRN : {patient_id}</h2>
              </div>
            </div>
          </div>
          {loading && (
            <div
              className="loading-spinner-overlay"
              data-testid="loading-spinner-overlay"
            >
              <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          <div className="scan-table-container">
            <div
              className={`scan-table-header ${
                role !== "Patient" ? "compact-header" : ""
              }`}
            >
              <div className="header-cell" onClick={sort}>
                <img
                  src={dateIcon}
                  alt="Sort Date"
                  className={`sort-icon ${isSortedAsc ? "asc" : "desc"}`}
                />
                Date
              </div>
              <div className="header-cell">Time</div>
              <div className="header-cell">Scan Type</div>
              <div className="header-cell">Source</div>
              {role === "Patient" && (
                <>
                  <div className="header-cell">View Access</div>
                  <div className="header-cell">Request Access</div>
                </>
              )}
            </div>

            <div
              className={`${scans.length === 0 ? "empty-state" : "table-body"}`}
            >
              {scans.map((user, index) => {
                const isAllowedToView =
                  role === "Patient"
                    ? patient.scans_allowed_to_view
                      ? patient.scans_allowed_to_view.includes(
                          user.study_UID.trim()
                        )
                      : false
                    : true;

                return (
                  <div
                    key={index}
                    className={`user-row ${
                      role !== "Patient" ? "compact-row" : ""
                    } ${
                      !isAllowedToView && role === "Patient"
                        ? "non-clickable"
                        : ""
                    }`}
                    onClick={
                      isAllowedToView ? () => setSelectedScan(user) : null
                    }
                  >
                    <div className="user-cell">
                      {handleDate(user.study_date)}
                    </div>
                    <div className="user-cell">
                      {handleTime(user.study_time)}
                    </div>
                    <div className="user-cell">{user.scan_type}</div>
                    <div className="user-cell">{user.source}</div>

                    {role === "Patient" && (
                      <>
                        {/* Column for allowed to view - tick or cross */}
                        <div className="user-cell">
                          {isAllowedToView ? (
                            <span className="view-icon">✔️</span>
                          ) : (
                            <span className="view-icon">❌</span>
                          )}
                        </div>

                        {/* Column for request access button */}
                        <div className="user-cell">
                          {!isAllowedToView && (
                            <button
                              className="request-access-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                sessionStorage.setItem(
                                  "studyId",
                                  user.study_UID
                                );
                                sessionStorage.setItem(
                                  "studyDate",
                                  user.study_date
                                );
                                window.open(
                                  "http://neuro42health.com/authForm",
                                  "_blank"
                                );
                              }}
                            >
                              Request Access
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              })}
            </div>

            <div className="pagination-container">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  siblingCount={0}
                  boundaryCount={1}
                  page={currentPage}
                  onChange={(event, page) => handlePageChange(page)}
                  shape="rounded"
                  showFirstButton
                  showLastButton
                  sx={{
                    "& .MuiPaginationItem-root": {
                      color: "#333333",
                      fontSize: "1vw",
                      borderRadius: "8px",
                      border: "1px solid #CCCCCC",
                    },
                    "& .Mui-selected": {
                      backgroundColor: "#66A091 !important",
                      color: "#FFFFFF !important",
                      fontSize: "1vw",
                      border: "1px solid #66A091",
                    },

                    "& .MuiPaginationItem-root:hover": {
                      backgroundColor: "#66A091",
                      color: "#FFFFFF",
                    },
                    "& .MuiPaginationItem-ellipsis": {
                      color: "#666666",
                    },
                    "& .MuiPaginationItem-icon": {
                      color: "#004C40",
                    },
                  }}
                />
              </Stack>

              <Select
                value={pageSize}
                onChange={(e) => handlePageSizeChange(Number(e.target.value))}
                variant="outlined"
                size="small"
                inputProps={{
                  "aria-label": "Items per page",
                }}
                style={{ minWidth: 60 }}
                sx={{
                  minHeight: 3,
                  borderRadius: "10px",
                  backgroundColor: "#ffffff",
                  color: "#000000",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#333333",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#000000",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#555555",
                  },
                  ".MuiSelect-select": {
                    padding: "8px 10px",
                    fontSize: "var(--font-size-l)",
                    color: "#000000",
                  },
                  ".MuiSelect-root": {
                    minHeight: "unset",
                  },
                }}
              >
                <MenuItem value={10}>10 per page</MenuItem>
                <MenuItem value={25}>25 per page</MenuItem>
                <MenuItem value={50}>50 per page</MenuItem>
              </Select>
            </div>
          </div>
        </div>
      </div>
      {isErrorOpen && (
        <div className="error-modal-overlay" data-testid="error-modal">
          <div className="error-modal-content">
            <div
              className="error-modal-header"
              data-testid="error-modal-header"
            >
              <img src={cross} alt="Error" className="cross-image" />
            </div>
            <h2 data-testid="error-title">Oh Snap!</h2>
            <p data-testid="error-message">
              {error.res || "An unexpected error occurred."}
            </p>
            <button
              className="error-ok-button"
              data-testid="error-ok-button"
              onClick={handleErrorClose}
            >
              Ok
            </button>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default OperatorScanListNew;
