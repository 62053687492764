import React from "react";
import "./OperatorPatientListNew2.css";
import NavbarNew from "../Navbar/NavbarNew";
import questionMark from "../Images/Vector.png";
import search from "../Images/Search.png";
import { FaArrowUp } from "react-icons/fa";
import PatientRow from "./PatientRow";
import AddIcon from "../Images/Add Icon.png";
import AddIconWhite from "../Images/Add Icon white.png";
import Pagination from "@mui/material/Pagination";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InvitePatientModal from "./InvitePatientModal";
import Footer from "./Footer";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useIdleTimer from "../../useIdleTimer";
import log from "../../logger.js";
// import Pagination from './Pagination';
import axios from "axios";
import cross from "../Images/cross.png";


const OperatorPatientListNew = () => {
  const storedUserObjectString = sessionStorage.getItem("userObject");

  const role = sessionStorage.getItem("role")

  const storedUserObject = JSON.parse(storedUserObjectString);
  const username = storedUserObject.first_name;

  const [patients, setPatients] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientDetails, setSelectedPatientDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [error, setError] = useState({
    email: "",
    res: "",
    first_name: "",
    last_name: "",
  });

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(1);
  const [isClicked, setIsClicked] = useState(false);
  const project = sessionStorage.getItem("project");
  const navigate = useNavigate();
  const handleIdle = () => {
    window.location.href = "/";
  };
  useIdleTimer(handleIdle, 1.2e6);

  const getToken = () => {

    const token = (role === "Physician" || role === "MRI-Tech" || role === "Nurse")
      ? sessionStorage.getItem("operator_token")
      : sessionStorage.getItem("admin_token");

    return token;
  };
  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  const handleGetPatients = async () => {
    try {
      const token = getToken();

      if (!token) {
        log.warn("No token found. Redirecting to login.", {
          endpoint: "/operator/get_all_patients/",
          method: "GET",
          project: project,
          response_status: 403,
          role: role
        });
        navigate("/physicians");
      }

      const response = await axios.get("/operator/get_all_patients/", {
        headers: {
          Authorization: `Bearer ${token}`,
          project: project,
        },
        params: {
          page: currentPage,
          page_size: pageSize,
          searchQuery: searchQuery.trim(),
        },
      });

      if (response.status === 200) {
        log.info("Patients fetched", {
          endpoint: "/operator/get_all_patients/",
          method: "GET",
          project: project,
          response_status: response.status,
          role: role
        });

        setPatients(response.data.patients);
        setTotalPages(response.data.total_pages || 1);
      } else {
        log.error("Failed to fetch patients. Non-200 response.", {
          endpoint: "/operator/get_all_patients/",
          method: "GET",
          response_status: response.status,
          project: project,
          error: error.message,
          stack: error.stack,
          role: role
        });
        if (!searchQuery.trim()) {
          setError({
            res: "Failed to fetch patients. Status: " + response.status,
          });
          setIsErrorOpen(true);
        }
      }
    } catch (error) {
      log.error("Error occurred while fetching patients.", {
        error: error.message,
        stack: error.stack,
        endpoint: "/operator/get_all_patients/",
        method: "GET",
        project: project,
        role: role
      });
      if (!searchQuery.trim()) {
        setError("Failed to fetch patients. Please try again.");
        setIsErrorOpen(true);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetPatients();
  }, [currentPage, pageSize, searchQuery]);

  const handleSearch = (value) => {
    setSearchQuery(value);
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (size) => {
    setPageSize(size);
    setCurrentPage(1);
  };
  // const handleLogout = async () => {
  //   await axios.post("/operator/logout/", {}, { withCredentials: true });
  //   localStorage.clear();
  //   sessionStorage.clear();
  // };

  const [icon, setIcon] = useState(AddIcon);
  const [textColor, setTextColor] = useState("#2A6E62");
  const [backgroundColor, setBackgroundColor] = useState("#E6F6F4");

  const handleMouseEnter = () => {
    setIcon(AddIconWhite);
    setTextColor("#FFFFFF");
    setBackgroundColor("#409E8D");
  };

  const handleMouseLeave = () => {
    setIcon(AddIcon);
    setTextColor("#2A6E62");
    setBackgroundColor("#E6F6F4");
  };

  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    height: "4vh",
    justifyContent: "center",
    width: "auto",
    minWidth: "10vw",
    padding: "1px 5px",
    fontSize: "var(--font-size-xl)",
    cursor: "pointer",
    marginLeft: "auto",
    marginRight: "0%",
    background: backgroundColor,
    mixBlendMode: "normal",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "2vw",
    fontFamily: "'Plus Jakarta Sans', sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "2vw",
    color: textColor,
    border: "none",
    transition: "color 0.3s ease",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    boxSizing: "border-box",
  };

  const iconStyle = {
    width: "calc(1.5vh + 0.5vw)",
    height: "calc(1.5vh + 0.5vw)",
    marginLeft: "0.5vw",
    transition: "opacity 0.3s ease",
  };

  return (
    <>
      <NavbarNew username={username} />
      <div className="main-content">
        <div className="patient-list-container-main">
          <div class="patient-list-title-container">
            <h2 className="title-patient-list">Patient List</h2>
            <div class="patient-list-tooltip-container">
              <img
                src={questionMark}
                alt="info-icon"
                class="patient-list-info-icon"
              />
              <span class="patient-list-tooltip-text">
                View the patient list here
              </span>
            </div>
          </div>

          <div className="patient-list-search-container">
            <img
              src={search}
              alt="search"
              className="patient-list-search-icon"
            />
            <input
              type="search"
              class="patient-list-search-input"
              placeholder="Search Patients..."
              value={searchQuery}
              onChange={(e) => handleSearch(e.target.value)}
              aria-label="Search Patients"
            />
          </div>

          <button
            className="add-patient-button-new"
            onClick={handleOpenModal}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={buttonStyle}
          >
            Add Patient
            <img
              src={icon}
              alt="AddIcon"
              className="add-icon"
              style={iconStyle}
            />
          </button>
        </div>

        <div className="patient-list-patient-table-container">
          {loading && (
            <div className="loading-spinner-overlay">
              <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          <div className="patient-list-table-header">
            <div className="patient-list-header-cell">MRN Number</div>
            <div className="patient-list-header-cell">First Name</div>
            <div className="patient-list-header-cell">Last Name</div>
            <div className="patient-list-header-cell">Email Address</div>
            <div className="patient-list-header-cell">Indication</div>
          </div>
          <div className="patient-list-table-body">
            {patients.map((patient, index) => (
              <PatientRow key={index} patient={patient} />
            ))}
          </div>

          {/* Pagination Section */}
          <div className="pagination-container">
            <Stack spacing={2}>
              <Pagination
                count={totalPages}
                siblingCount={0}
                boundaryCount={1}
                page={currentPage}
                onChange={(event, page) => handlePageChange(page)}
                shape="rounded"
                showFirstButton
                showLastButton
                sx={{
                  "& .MuiPaginationItem-root": {
                    color: "#333333",
                    fontSize: "1vw",
                    borderRadius: "8px",
                    border: "1px solid #CCCCCC",
                  },
                  "& .Mui-selected": {
                    backgroundColor: "#66A091 !important",
                    color: "#FFFFFF !important",
                    fontSize: "1vw",
                    border: "1px solid #66A091",
                  },

                  "& .MuiPaginationItem-root:hover": {
                    backgroundColor: "#66A091",
                    color: "#FFFFFF",
                  },
                  "& .MuiPaginationItem-ellipsis": {
                    color: "#666666",
                  },
                  "& .MuiPaginationItem-icon": {
                    color: "#004C40",
                  },
                }}
              />
            </Stack>

            <Select
              value={pageSize}
              onChange={(e) => handlePageSizeChange(Number(e.target.value))}
              variant="outlined"
              size="small"
              inputProps={{
                "aria-label": "Items per page",
              }}
              style={{ minWidth: 60 }}
              sx={{
                minHeight: 3,
                borderRadius: "10px",
                backgroundColor: "#ffffff",
                color: "#000000",
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: "#333333",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#000000",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#555555",
                },
                ".MuiSelect-select": {
                  padding: "8px 10px",
                  fontSize: "var(--font-size-l)",
                  color: "#000000",
                },
                ".MuiSelect-root": {
                  minHeight: "unset",
                },
              }}
            >
              <MenuItem value={10}>10 per page</MenuItem>
              <MenuItem value={25}>25 per page</MenuItem>
              <MenuItem value={50}>50 per page</MenuItem>
            </Select>
          </div>
        </div>
        <InvitePatientModal open={isModalOpen} onClose={handleCloseModal} />
      </div>
      {isErrorOpen && (
        <div className="error-modal-overlay">
          <div className="error-modal-content">
            <div className="error-modal-header">
              <img src={cross} alt="Error" className="cross-image" />
            </div>
            <h2>Oh Snap!</h2>
            <p>{error}</p>
            <button className="error-ok-button" onClick={handleErrorClose}>
              Ok
            </button>
          </div>
        </div>
      )}
      <Footer />
    </>
  );
};

export default OperatorPatientListNew;
